<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
import Multiselect from "vue-multiselect";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  props: { currentEmpresa: { type: Object } },
  components: {
    Multiselect,
  },
  data() {
    return {
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },

      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas:[
        {
          id:1,
          nome:"plano 01"
        },
        {
          id:2,
          nome:"plano 02"
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano:{
        woner_id  : null,
        descricao : null,      
        natureza : null,      
        codigo_reduzido : null,
        classificacao  : null
      }
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Plano de Contas");
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
    this.generatePDF();
  },
  methods: {
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
       const ctx = canvas.getContext('2d');
       ctx.drawImage(img, 0, 0);
       return canvas.toDataURL('image/png');
   },
   generatePDF2() {
      const doc = new jsPDF();

      // Configurações iniciais
      doc.setFontSize(12);

      // Cabeçalho
      doc.text('FICHA DE ATENDIMENTO POR SESSÕES', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
      doc.text(`Data: ${new Date().toLocaleDateString()}`, 10, 20);

      // Tabela
      const headers = ['Data', 'Hora Inicial', 'Total de Sessões', 'Local', 'Assinatura Profissional', 'Assinatura Responsável'];
      const data = this.tableData;

      // Configuração da tabela (ajuste conforme necessário)
      const options = {
        startY: 30,
        headStyles: { fillColor: [0, 0, 255], textColor: [255, 255, 255] },
        bodyStyles: { valign: 'middle' }
      };

      // Criar a tabela
      doc.autoTable(headers, data, options);

      // Rodapé
      const y = doc.lastAutoTable.finalY + 10;
      doc.text('CRESCER - Centro de Especialidades Terapéuticas', 10, y);
      doc.text('Rua das Flores, 123 - Centro', 10, y + 10);

      // Salvar o PDF
      doc.save('minha_ficha.pdf');
    },
    generatePDF() {
      // Cria o documento PDF em modo paisagem
      const doc = new jsPDF('landscape');

      // Adiciona o logotipo
      // const logo = 'data:image/png;base64,iVBORw0...'; // Substitua pela sua string base64
      // doc.addImage(logo, 'PNG', 250, 10, 40, 20); // (imagem, tipo, x, y, largura, altura)

      doc.setFontSize(16);
      doc.text('Fichas de Atendimento', 148, 30, null, null, 'center');
      doc.setFontSize(12);
      doc.text('Terapias Continuadas', 148, 35, null, null, 'center');

      doc.setFontSize(10);

      // Texto e linhas correspondentes
      doc.text('Nome do paciente:', 10, 45);
      doc.line(60, 45, 280, 45); // Desenha a linha

      doc.text('Nome do Responsável:', 10, 50);
      doc.line(60, 50, 280, 50); // Desenha a linha

      doc.text('Contato telefônico:', 10, 55);
      doc.line(60, 55, 280, 55); // Desenha a linha

      doc.text('Tratamento:', 10, 60);
      doc.line(60, 60, 280, 60); // Desenha a linha

      doc.text('Abordagem utilizada:', 10, 65);
      doc.line(60, 65, 280, 65); // Desenha a linha

      doc.text('Profissional executante:', 10, 70);
      doc.line(60, 70, 280, 70);
      // Configuração da tabela
      const tableColumn = [
        { title: "Data", dataKey: "data" },
        { title: "Hora inicial", dataKey: "horaInicial" },
        { title: "Total de Sessões", dataKey: "totalSessoes" },
        { title: "Local (Clínica/escola/casa)", dataKey: "local" },
        { title: "Assinatura profissional executante", dataKey: "assinaturaProfissional" },
        { title: "Assinatura responsável", dataKey: "assinaturaResponsavel" },
      ];

      // Linhas vazias para preenchimento futuro (ajustado para 10 linhas)
      const tableRows = Array(21).fill({
        data: "",
        horaInicial: "",
        totalSessoes: "",
        local: "",
        assinaturaProfissional: "",
        assinaturaResponsavel: "",
      });

      // Adiciona a tabela ao PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 80,
        theme: 'grid',
        styles: { 
          fontSize: 8, // Mantendo a fonte reduzida
          cellPadding: 1, // Reduzindo o padding para diminuir a altura das células
          valign: 'middle',
          halign: 'center',
          rowHeight: 3, // Reduzindo a altura das células
        },
        headStyles: {
          fillColor: [255, 255, 255], // Branco para o fundo do cabeçalho
          textColor: [0, 0, 0],       // Preto para o texto do cabeçalho
          fontStyle: 'bold',
        },
        tableWidth: 'auto',
        columnStyles: {
          data: { cellWidth: 50 },
          horaInicial: { cellWidth: 20 },
          totalSessoes: { cellWidth: 30 },
          local: { cellWidth: 70 },
          assinaturaProfissional: { cellWidth: 60 },
          assinaturaResponsavel: { cellWidth: 60 },
        },
      });

      // Salva o PDF
       // Adiciona o texto no final
  const footerText = "CRESCER - Centro de Especialidades Terapêuticas - CNPJ:33.339.726/0001-02 - Estabelecida na Travessa Maurício de Nassau, nº187, Gercino Coelho - Petrolina, CEP: 56.306-011 - Pernambuco";
  
  // Ajuste a coordenada Y para garantir que o texto não sobreponha o conteúdo da tabela
  const yPosition = doc.internal.pageSize.height - 10;
  
  doc.setFontSize(8); // Ajuste o tamanho da fonte conforme necessário
  doc.text(footerText, 10, yPosition, { maxWidth: 280 }); // maxWidth garante que o texto não ultrapasse a largura da página

  // Salva o PDF
  // doc.save('ficha_de_atendimento_paisagem.pdf');

      doc.save('ficha_de_atendimento_paisagem.pdf');
    },

    abr_multiselect(valor) {
      console.log("entrar");
      if(valor == 1){
         console.log(valor)
        this.show_multiselect = true
      }else {
        console.log("esntrou no elsse" + valor)
        this.show_multiselect = false
      }
    },
    setPost() {
      this.loader.get = true;
      setTimeout(function () {
        this.loader.get = false;
      }, 2000);
      this.$emit("doPost", this.plano);
    },
    selecionar_plano(event){
      this.plano.woner_id = event.id
    },
    selecionar_tipos(event){
      this.plano.classificacao = event.id
    },
    seleciona_orientacoes(event){
      this.plano.natureza = event.id
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <label for="">{{ "*Código" }}</label>
          <input v-model="plano.codigo_reduzido" type="text" class="form-control text-left" />
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Titulo" }}</label>
          <input v-model="plano.descricao" type="text" class="form-control text-left" />
        </div>
      </div>
      <div class="row mb-4 mt-4">
        <div class="col-md-6">
          <label for="">{{ "*Orientação" }}</label>
          <multiselect
            v-model="select_orientacao"
            @select="seleciona_orientacoes($event)"
            :options="orientacoes"
            label="nome"
          >
          </multiselect>
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Tipo" }}</label>
          <multiselect @select="selecionar_tipos($event)" v-model="select_tipo" :options="tipos" label="nome">
          </multiselect>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-4">
        <div class="col-md-12 text-center">
          <b-form-checkbox @change="abr_multiselect($event)">
            Vincular a outro Plano
          </b-form-checkbox>
          <!-- <input type="text" class="form-control text-left"> -->
        </div>
      </div>
      <div class="row" v-if="show_multiselect">
          <div class="col-md-12">
             <multiselect v-model="select_plano_conta" :options="planos_contas" label="nome" @select="selecionar_plano($event)">

             </multiselect>
          </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button v-if="!loader.get" class="btn btn-light" @click.prevent="setPost()">
          Salvar
        </button>
        <i
          v-if="loader.get"
          class="fa fa-spinner fa-4x fa-spin text-success"
        ></i>
      </div>
    </div>
  </div>
</template>